import {
  type OrderAPI,
  type Order,
  type GraphQlOrderError,
  type OrderTrackingResponse,
} from '@/features/shop/services/Orders/types'
import {
  normalizeOrder,
  transformOrderTrackingProduct,
} from '@/features/shop/services/Orders/utils'
import { apiGet } from '@/features/shared/utils/dataFetching'
import {
  normalizeGraphqlOrder,
  normalizeGraphqlOrderHistory,
} from '@/features/shop/services/Orders/utils/graphqlConversion'
import { logWarn } from '@/features/shared/utils/logger'
import {
  ErrorResponse,
  getErrorMessage,
} from '@/features/shared/utils/dataFetching/utils'
import { isGraphQlOrderError } from '@/features/shop/services/Orders/utils/guards'
import { type QueryFunctionContext } from '@tanstack/react-query'
import { useOrderQuery } from '@/features/shop/services/Order/graphql/order.generated'
import {
  type OrderTrackingQueryKey,
  type ActiveOrdersQueryKey,
  type OrderQueryKey,
  orderQueryOptions,
} from '@/features/shop/services/Orders/queries'
import { useLastDeliveredOrderQuery } from '@/features/shop/services/Orders/graphql/lastDeliveredOrder.generated'
import { type TransformedOrderTracking } from '@/features/shop/services/Product/types'
import { useOrderHistoryQuery } from '@/features/shop/services/Orders/graphql/orderHistory.generated'

export const fetchActiveOrders = async (
  context: QueryFunctionContext<ActiveOrdersQueryKey>
) => {
  const [, { storeParams }] = context.queryKey
  const orders = await apiGet<OrderAPI[]>({
    config: { url: 'aviator/v2/orders/active.json', params: storeParams },
    context,
    fetcherName: 'fetchActiveOrders',
  })
  return orders.map(normalizeOrder)
}

const catchGraphQLError = (
  err: GraphQlOrderError,
  context: QueryFunctionContext
) => {
  const message = err?.response?.errors?.[0]?.message ?? getErrorMessage(err)

  const exceptionPath = err?.response?.errors?.[0]?.path

  const orderResponse = err.response?.data

  if (
    exceptionPath?.includes('preferred_shopper') &&
    typeof orderResponse === 'object' &&
    'order' in orderResponse
  ) {
    const ssrContext = context.meta?.ssrContext
    const { order } = orderResponse
    // sets order so order details renders despite the error in preferredShopper
    logWarn('preferredShopper error when getting order details', {
      ...(ssrContext && { pageData: { url: ssrContext.req?.url } }),
    })
    const normalizedOrder = normalizeGraphqlOrder(order)
    context.client.setQueryData(
      orderQueryOptions(normalizedOrder?.id).queryKey,
      normalizedOrder
    )
    return normalizedOrder
  }
  // handle remaining errors by throwing an ErrorMessage type with
  // parsing of the response
  const [statusStr = ''] = message.split(':')
  let status = parseInt(statusStr)
  status = isNaN(status) ? 500 : status
  throw new ErrorResponse({ status, message })
}

export const fetchOrder = async (
  context: QueryFunctionContext<OrderQueryKey>
): Promise<Order | undefined> => {
  try {
    // we need to await here so that graphql errors are caught here
    // and not in the parent
    const response = await useOrderQuery.fetcher(context.queryKey[1])(context)
    return normalizeGraphqlOrder(response.order)
  } catch (e) {
    if (!isGraphQlOrderError(e)) {
      throw e
    }
    return catchGraphQLError(e, context)
  }
}

export const fetchOrdersHistory = async (context: QueryFunctionContext) => {
  const data = await useOrderHistoryQuery.fetcher()(context)
  const normalizedData = normalizeGraphqlOrderHistory(data.orderHistory)
  return normalizedData
}

export const fetchLastDeliveredOrder = async (
  context: QueryFunctionContext
) => {
  const res = await useLastDeliveredOrderQuery.fetcher()(context)
  return normalizeGraphqlOrder(res?.lastDeliveredOrder)
}

export const fetchOrderTracking = async (
  context: QueryFunctionContext<OrderTrackingQueryKey>
): Promise<TransformedOrderTracking> => {
  const [, orderId] = context.queryKey

  const response = await apiGet<OrderTrackingResponse>({
    config: { url: `aviator/v1/member/order-tracking/${orderId}` },
    context,
    fetcherName: 'fetchOrderTracking',
  })

  return transformOrderTrackingProduct(response)
}
